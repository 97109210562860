import { ITaxonomyTerms } from '@kontent-ai/delivery-sdk';
import { ProdClient } from 'client/client';
import { FeaturedContent } from 'components/modules/FeaturedContent';
import { HeadComponent } from 'components/pages/Head';
import OverviewTemplate from 'components/pages/Overview';
import { fetchInitialUrlFilter } from 'helpers/getLocaleKey';
import cacheData from 'memory-cache';
import { ContentPage, FeaturedSpots, OverviewPage } from 'models';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { UpdatedTaxonomyTerms } from 'services/types';
import slugify from 'slugify';
import { withTranslations } from 'store/translations';
import { alphabeticalSort } from 'utils/alphabeticalSort';
import { onlyDefined } from 'utils/definedInArray';
import { fetchOverviewVariants } from 'utils/getOverviewPage';
import { OptionsProps } from 'utils/overviewFunctionality';

interface InsightsOverviewProps {
  contentPages?: ContentPage[];
  featuredSpot: FeaturedSpots;
  theme: ITaxonomyTerms[];
  type: ITaxonomyTerms[];
  urlTheme: string | null;
  overviewPage: OverviewPage;
}
// const {
//   theme_topic:
//   multiple,
// } = await getTranslations();
export default function InsightsOverview({
  contentPages,
  featuredSpot,
  theme,
  type,
  urlTheme,
  overviewPage,
}: InsightsOverviewProps) {
  const { common, multiple, theme_topic, content_type } = withTranslations();
  const translations = withTranslations();
  const { locale } = useRouter();
  let theme_translate;
  const [isHydrated, setHydrated] = useState(false);
  //  const [hydrated, setHydrated] = useState(false);
  const theme_en = {
    '': '',
    biodiversity___ecosystems: 'Biodiversity and Ecosystems',
    decarbonizing_for_net_zero: 'Decarbonise for net zero',
    our_sustainability_impact: 'Our Sustainability Impact',
    resilient_societies_and_liveability: 'Resilient societies and liveability',
    resource_management_and_circular_economy: 'Resource management and circular economy',
  };
  const theme_dk = {
    biodiversity___ecosystems: 'Biodiversitet og økosystemer',
    decarbonizing_for_net_zero: 'CO2 reduktion',
    our_sustainability_impact: 'Vores bæredygtighedsmæssige påvirkning',
    resilient_societies_and_liveability: 'Bæredygtige byer og samfund, der er gode at leve i',
    resource_management_and_circular_economy: 'Ressourcehåndtering og cirkulær økonomi',
  };
  const theme_de = {
    '': '',
    biodiversity___ecosystems: 'Biodiversität & Ökosysteme',
    decarbonizing_for_net_zero: 'Dekarbonisierung für net zero',
    our_sustainability_impact: 'Unser Beitrag zur Nachhaltigkeit',
    resilient_societies_and_liveability: 'Resiliente Gesellschaft und Lebensqualität',
    resource_management_and_circular_economy: 'Ressourcenmanagement und Kreislaufwirtschaft',
  };
  const theme_fi = {
    '': '',
    biodiversity___ecosystems: 'Luonnon monimuotoisuus ja ekosysteemit',
    decarbonizing_for_net_zero: 'Hiilidioksipäästöjen tavoitteena nettonolla',
    our_sustainability_impact: 'Ympäristö-, ilmasto- ja yhteiskunnalliset tavoitteemme',
    resilient_societies_and_liveability: 'Kestävät ja elinvoimaiset yhteiskunnat',
    resource_management_and_circular_economy: 'Resurssitehokkuus ja kiertotalous',
  };
  const theme_no = {
    biodiversity___ecosystems: 'Biologisk mangfold og økosystemer',
    decarbonizing_for_net_zero: 'Dekarbonisering for netto null ',
    our_sustainability_impact: 'vår effekt på bærekraft',
    resilient_societies_and_liveability: 'Robuste samfunn og levbarhet',
    resource_management_and_circular_economy: 'ressursstyring og sirkulærøkonomi',
  };
  const theme_sv = {
    '': '',
    biodiversity___ecosystems: 'Biologisk mångfald & Ekosystem',
    decarbonizing_for_net_zero: 'Avkarbonisiera för nettonollutsläpp',
    our_sustainability_impact: 'Vår hållbarhetspåverkan',
    resilient_societies_and_liveability: 'Stadsutveckling kopplad till resiliens och hållbarhet',
    resource_management_and_circular_economy: 'Resurshushållning och cirkulär ekonomi',
  };
  // ------------------------ FUNCTIONS ------------------------
  // -- Divide pages to show into arrays of 10 pages --
  const dividePagesToShow = () => {
    let dividePagesByTen = 0;
    // Use this instead of index due to some pages not showing w/ filters
    let currentIndex = 0;
    // const total = contentPages && overviewPage.elements.manualAdditionOfPages.linkedItems.concat(contentPages);
    const pagesToShowTemp: [ContentPage[]] = [[]];
    contentPages?.forEach((page) => {
      if (currentIndex % 10 === 0 && currentIndex !== 0 && pagesToShowTemp[dividePagesByTen]?.length >= 10) {
        dividePagesByTen++;
      }
      if (page.elements.tagsThemeTopic.value?.length > 0) {
        page.elements.tagsThemeTopic.value.forEach((theme) => {
          const themeCodename = theme?.codename;
          const contentTypeCodename = page.elements.tagsContentType.value[0]?.codename;
          if (
            (filterTheme.includes(themeCodename) || !filterTheme[0]) &&
            (filterType.includes(contentTypeCodename) || !filterType[0])
          ) {
            if (!Array.isArray(pagesToShowTemp[dividePagesByTen])) {
              pagesToShowTemp[dividePagesByTen] = [];
            }
            const containsValue = pagesToShowTemp[dividePagesByTen].some(
              (obj) => obj.system.codename === page.system.codename,
            );
            if (
              overviewPage.elements.enableCuration.value[0]?.codename == 'yes' &&
              overviewPage.elements.manualAdditionOfPages.linkedItems.length > 0 &&
              filterTheme.length == 0 &&
              dividePagesByTen == 0
            ) {
              pagesToShowTemp[dividePagesByTen] = overviewPage.elements.manualAdditionOfPages.linkedItems;
            } else {
              !containsValue && pagesToShowTemp[dividePagesByTen].push(page);
            }
            pagesToShowTemp[dividePagesByTen].length >= 10 && dividePagesByTen++;

            // currentIndex++;
          }
        });
      } else {
        const themeCodename = page.elements.tagsThemeTopic.value[0]?.codename;
        const contentTypeCodename = page.elements.tagsContentType.value[0]?.codename;
        if (
          (filterTheme.includes(themeCodename) || !filterTheme[0]) &&
          (filterType.includes(contentTypeCodename) || !filterType[0])
        ) {
          if (!Array.isArray(pagesToShowTemp[dividePagesByTen])) {
            pagesToShowTemp[dividePagesByTen] = [];
          }

          if (
            overviewPage.elements.enableCuration.value[0]?.codename == 'yes' &&
            overviewPage.elements.manualAdditionOfPages.linkedItems.length > 0 &&
            filterTheme.length == 0 &&
            dividePagesByTen == 0
          ) {
            pagesToShowTemp[dividePagesByTen] = overviewPage.elements.manualAdditionOfPages.linkedItems;
          } else {
            pagesToShowTemp[dividePagesByTen]?.length < 10 && pagesToShowTemp[dividePagesByTen].push(page);
          }
          // pagesToShowTemp[dividePagesByTen].length >= 10 && dividePagesByTen++;
        }
      }
      currentIndex++;
    });
    return onlyDefined(pagesToShowTemp);
  };

  // -- Functions that check amount of pages with filters --
  const updateFilterTheme = (): UpdatedTaxonomyTerms[] => {
    //setPagination(0);
    return theme
      .map((theme) => {
        let amountOfPagesWithTag = 0;
        contentPages?.forEach((page) => {
          page.elements.tagsThemeTopic.value.forEach((tag) => {
            const themeCodename = tag.codename;
            const typeCodename = page.elements.tagsContentType.value[0]?.codename;
            if (theme.codename === themeCodename && (filterType.includes(typeCodename) || !filterType[0])) {
              amountOfPagesWithTag++;
            }
          });
        });

        // const themeCodename = page.elements.tagsThemeTopic.value[0]?.codename;
        // const typeCodename = page.elements.tagsContentType.value[0]?.codename;
        // if (theme.codename === themeCodename && (filterType.includes(typeCodename) || !filterType[0])) {
        //   amountOfPagesWithTag++;
        // }
        //   });
        //theme_topic(theme.codename)
        return Object.assign({
          amountOfPagesWithTag: amountOfPagesWithTag,
          ...theme,
          name:
            cacheData.get('insight_theme') != null && theme_translate
              ? theme_translate[theme?.codename]
              : theme_topic(theme?.codename),
        });
      })
      .sort((a, b) => alphabeticalSort(a.name, b.name));
  };

  const updateFilterType = () => {
    // setPagination(0);
    return type
      .map((type) => {
        let amountOfPagesWithTag = 0;
        contentPages?.forEach((page) => {
          const themeCodename = page.elements.tagsThemeTopic.value[0]?.codename;
          const typeCodename = page.elements.tagsContentType.value[0]?.codename;
          if (type.codename === typeCodename && (filterTheme.includes(themeCodename) || !filterTheme[0])) {
            amountOfPagesWithTag++;
          }
        });
        return Object.assign({
          amountOfPagesWithTag: amountOfPagesWithTag,
          ...type,
          name: content_type(type.codename),
        });
      })
      .sort((a, b) => alphabeticalSort(a.name, b.name));
  };
  // -- Functions that check amount of pages with filters End --

  // -- URL based functions --
  const updateFiltersInUrl = () => {
    const removeQueries = router.asPath.includes('?') && router.asPath.split('?').reverse().pop();
    const urlArray =
      removeQueries !== false && removeQueries !== undefined ? removeQueries.split('/') : router.asPath.split('/');
    urlArray.length >= 3 && urlArray.pop();
    // urlArray.push(...filterTheme.map((theme) => slugify(theme_topic(theme), { lower: true })));
    const urlString = filterType[0]
      ? urlArray.join('/') +
        `?${slugify(common('type'), { lower: true })}=${filterType.map((type) =>
          slugify(content_type(type), { lower: true }),
        )}`
      : filterTheme[0] && !filterType[0]
      ? urlArray.join('/') +
        `?${slugify(common('theme_topic'), { lower: true })}=${filterTheme
          .map((theme) => slugify(theme_topic(theme), { lower: true }))
          .join(',')}`
      : filterTheme[0] && filterType[0]
      ? urlArray.join('/') +
        `?${slugify(common('theme_topic'), { lower: true })}=${filterTheme
          .map((theme) => slugify(theme_topic(theme), { lower: true }))
          .join(',')}` +
        `&${slugify(common('type'), { lower: true })}=${filterType.map((type) =>
          slugify(content_type(type), { lower: true }),
        )}`
      : urlArray.join('/');

    router.replace(urlString, undefined, { shallow: true, scroll: false });
  };

  const getTypeFilterFromUrl = () => {
    return type.filter((e) => {
      return (
        slugify(content_type(e.codename), { lower: true }) ===
        router.asPath.split(`${slugify(common('type'), { lower: true })}=`).slice(-1)[0]
      );
    }).length;
  };
  // -- URL based functions End --
  // ------------------------ FUNCTIONS END ------------------------

  // ------------------------ VARIABLES ------------------------
  const [filterTheme, setFilterTheme] = useState<string[]>(urlTheme ? [urlTheme] : []);
  const [filterType, setFilterType] = useState<string[]>([]);
  const [amountOfFilters, setAmountOfFilters] = useState(0);
  const [pagesToShow, setPagesToShow] = useState<ContentPage[][]>(dividePagesToShow());
  const [themeState, setThemeState] = useState<OptionsProps[]>([]);
  const [typeState, setTypeState] = useState<OptionsProps[]>([]);
  const router = useRouter();
  switch (router.locale) {
    case 'en':
      theme_translate = theme_en;
      break;
    case 'en-epac':
      theme_translate = theme_en;
      break;
    case 'en-us':
      theme_translate = theme_en;
      break;
    case 'en-gb':
      theme_translate = theme_en;
      break;
    case 'da-dk':
      theme_translate = theme_dk;
      break;
    case 'de-de':
      theme_translate = theme_de;
      break;
    case 'fi-fi':
      theme_translate = theme_fi;
      break;
    case 'no-no':
      theme_translate = theme_no;
      break;
    case 'sv-se':
      theme_translate = theme_sv;
      break;
  }

  // ------------------------ VARIABLES END ------------------------

  // ------------------------ USE EFFECTS ------------------------
  useEffect(() => {
    const newThemes = updateFilterTheme();
    const newType = updateFilterType();
    setThemeState(newThemes);
    setTypeState(newType);
    setPagesToShow(dividePagesToShow());
    // If there's a theme in the URL set it to filterTheme
    urlTheme && fetchInitialUrlFilter('theme_topic', urlTheme, setFilterTheme, locale);
    const typeFromUrl =
      getTypeFilterFromUrl() > 0 && router.asPath.split(`${slugify(common('type'), { lower: true })}=`).slice(-1);
    typeFromUrl && fetchInitialUrlFilter('content_type', typeFromUrl, setFilterType, locale);
    setHydrated(true);
  }, []);
  useEffect(() => {
    const newThemes = updateFilterTheme();
    const newType = updateFilterType();
    setThemeState(newThemes);
    setTypeState(newType);
    setPagesToShow(dividePagesToShow());
    // If there's a theme in the URL set it to filterTheme
    urlTheme && fetchInitialUrlFilter('theme_topic', urlTheme, setFilterTheme, locale);
    const typeFromUrl =
      getTypeFilterFromUrl() > 0 && router.asPath.split(`${slugify(common('type'), { lower: true })}=`).slice(-1);
    typeFromUrl && fetchInitialUrlFilter('content_type', typeFromUrl, setFilterType, locale);
    setHydrated(true);
  }, [router.locale, router.isReady, translations.isReady]);
  // -- Runs when filters change
  useEffect(() => {
    setHydrated(true);

    setAmountOfFilters(filterType.length + filterTheme.length);
    updateFiltersInUrl();
    const newThemes = updateFilterTheme();

    const newType = updateFilterType();
    setThemeState(newThemes);
    setTypeState(newType);

    setPagesToShow(dividePagesToShow());
  }, [filterType, filterTheme]);
  if (!isHydrated) {
    // Returns null on first render, so the client and server match
    return null;
  }
  // Setting initial url filters
  // ------------------------ USE EFFECTS END ------------------------
  return (
    <>
      <HeadComponent page={overviewPage} />

      {!!featuredSpot && <FeaturedContent model={featuredSpot} />}
      <OverviewTemplate
        PagesToShow={pagesToShow}
        overviewTitle={multiple('insight')}
        amountOfFilters={amountOfFilters}
        filterTwo={{
          value: filterTheme,
          set: setFilterTheme,
          state: themeState,
          label: 'theme_topic',
        }}
        filterThree={{
          value: filterType,
          set: setFilterType,
          state: typeState,
          label: 'content_type',
        }}
      />
    </>
  );
}

export const getStaticInsightsProps = async ({ locale, urlParam }: { locale: string; urlParam?: string }) => {
  const languageCodename = locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
  const overviewPage = await ProdClient.items<OverviewPage>()
    .type('overview_page')
    .containsFilter('elements.category', ['insight'])
    .languageParameter(languageCodename)
    .limitParameter(1)
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((response) => {
      return response.data.items[0];
    });
  const contentPages = await ProdClient.items<ContentPage>()
    .type('content_page')
    .containsFilter('elements.category', ['insight'])
    .languageParameter(languageCodename)
    .equalsFilter('system.language', languageCodename)
    .orderByDescending('elements.summary_date')
    .elementsParameter([
      'url',
      'featured',
      'category',
      'summary__title',
      'summary__description',
      'summary__image_frontify',
      'part_of_campaign_page',
      'summary__date',
      'tags__sector',
      'tags__theme_topic',
      'tags__content_type',
    ])
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((response) => {
      const pages = response.data.items;
      const contentPageArr: ContentPage[] = [];
      let pageIndex = 0;
      pages.map((item) => {
        // overviewPage.elements.manualAdditionOfPages.linkedItems.map((page)=>{
        if (
          item.system.codename ==
            overviewPage.elements.manualAdditionOfPages?.linkedItems[pageIndex]?.system.codename &&
          pageIndex < overviewPage.elements.manualAdditionOfPages.linkedItems.length
        ) {
          const index = pages.indexOf(item);
          if (index != -1) {
            pages.splice(index, 1);
          }
          pageIndex++;
        } else {
          contentPageArr.push(item);
        }
      });
      return pages.sort((a, b) =>
        Date.parse(a.elements.summaryDate.value ?? a.system.lastModified) <
        Date.parse(b.elements.summaryDate.value ?? b.system.lastModified)
          ? 1
          : -1,
      ) as ContentPage[];
      // .sort((a, b) =>
      //   (a.elements.featured.value[0]?.codename ? a.elements.featured.value[0].codename : 'no') >
      //   (b.elements.featured.value[0]?.codename ? b.elements.featured.value[0]?.codename : 'no')
      //     ? -1
      //     : 1,
      // ) as ContentPage[];
    });

  const featuredSpot =
    !!overviewPage &&
    !!overviewPage.elements.hero.value[0] &&
    (await ProdClient.item<FeaturedSpots>(overviewPage.elements.hero.value[0])
      .languageParameter(languageCodename)
      .depthParameter(2)
      .toPromise()
      .then((response) => {
        return response.data.item;
      }));

  const theme =
    cacheData.get('taxonomy_theme_topic') == null
      ? await ProdClient.taxonomy('theme_topic')
          .toPromise()
          .then((response) => {
            cacheData.put('taxonomy_theme_topic', response.data.taxonomy.terms, 86400000, function () {
              console.log('clearing the cache');
              cacheData.del('taxonomy_theme_topic'); //to clear the existing cache after 24hr
            });
            return response.data.taxonomy.terms;
          })
      : cacheData.get('taxonomy_theme_topic');

  const type =
    cacheData.get('taxonomy_media_type') == null
      ? await ProdClient.taxonomy('media_type')
          .toPromise()
          .then((response) => {
            cacheData.put('taxonomy_media_type', response.data.taxonomy.terms, 86400000, function () {
              console.log('clearing the cache');
              cacheData.del('taxonomy_media_type'); //to clear the existing cache after 24hr
            });
            return response.data.taxonomy.terms;
          })
      : cacheData.get('taxonomy_media_type');

  const variants = await fetchOverviewVariants('insight');

  return {
    variants,
    contentPages,
    featuredSpot,
    theme,
    type,
    urlTheme: urlParam ?? null,
    overviewPage,
  };
};
